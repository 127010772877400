<template>
  <div class="full-width full-height bg-blue  text-lightblue p-md">
    <back url="/resource/index" />
    <div class="flex items-center justify-between m-t-sm m-b-sm">
      <div class="fl-menu flex items-center">
        <span
          v-for="(item, index) in subList"
          :key="item.id"
          :class="[{ current: activeMenu === index }, 'pointer']"
          @click="tabClick(index, item)"
          >{{ item.name }}</span
        >
        <!-- <span
          :class="[{ current: activeMenu === 1 }, 'pointer']"
          @click="tabClick(1)"
          >按区域</span
        >
        <span
          :class="[{ current: activeMenu === 2 }, 'pointer']"
          @click="tabClick(2)"
          >按单位</span
        > -->
      </div>
      <div class="flex items-center">
        <!-- 按路线 -->
        <div class="m-r-sm" v-show="currentType === 'road'">
          <a-select
            placeholder="请选择所属路线"
            v-model="roadId"
            class="search-mx-wd-1"
            @change="selectRoad"
          >
            <a-select-option
              v-for="item in roadList"
              :key="item.roadId"
              :value="item.roadId"
            >
              {{ item.roadCode + ' ' + item.roadName }}
            </a-select-option>
          </a-select>
        </div>
        <!-- 按区域 -->
        <div class="m-r-sm" v-show="currentType === 'province'">
          <a-cascader
            :options="selectList"
            :display-render="displayRender"
            change-on-select
            v-model="regionId"
            class="m-r-xs search-mx-wd-1"
            expand-trigger="hover"
            placeholder="请选择地区"
            @change="changeProvince"
          />
        </div>
        <!-- 按单位 -->
        <div class="m-r-sm" v-show="currentType === 'organization'">
          <a-cascader
            :options="organizationDataList"
            :display-render="displayRender"
            change-on-select
            v-model="organizationId"
            class="m-r-xs search-mx-wd-1"
            expand-trigger="hover"
            placeholder="管辖单位"
            @change="selectOrganization"
          />
          <a-input
            placeholder="请输入路线名称"
            v-model="routeName"
            v-if="false"
          />
        </div>
        <a-button
          type="primary"
          class="m-r-sm btn-search"
          @click="searchHandle"
        >
          搜索
        </a-button>
        <a-button class="btn-reset" @click="resetHandle">
          重置
        </a-button>
        <span class="span-line m-l-md m-r-md"></span>
        <a-button class="btn-export" @click="exportNetworkStatisticsHandle">
          数据导出
        </a-button>
      </div>
    </div>
    <vue-scroll :ops="$root.scrollOpsY" style="height:calc(100% - 80px);">
      <div class="table-con">
        <a-table
          :columns="columns"
          :data-source="roadLists"
          :rowKey="(record, index) => record.key"
          :pagination="false"
          @change="changeHandle"
          v-if="currentType === 'road' && $g.isDepart"
          @expand="expandHandle"
          bordered
        >
          <span
            slot="name"
            slot-scope="text, record"
            @click="clickRoadHandle(record)"
          >
            {{ text }}
          </span>
          <span
            slot="customCutInRate"
            slot-scope="text"
            :style="{ color: getColor(text) }"
          >
            {{ text }}
          </span>
          <span
            slot="customOnlineRate"
            slot-scope="text"
            :style="{ color: getColor(text) }"
          >
            {{ text }}
          </span>
          <span
            slot="customOfflineRate"
            slot-scope="text"
            :style="{ color: getColor(text) }"
          >
            {{ text }}
          </span>
          <span slot="totalAccessCount" slot-scope="text">
            {{ !!text ? text : '/' }}
          </span>
          <span slot="accessedRate" slot-scope="text">
            {{ !!text ? text : '/' }}
          </span>
        </a-table>
        <a-table
          :columns="columns"
          :data-source="regionLists"
          :rowKey="(record, index) => record.key"
          :pagination="false"
          @change="changeHandle"
          v-if="currentType === 'province' && $g.isDepart"
          @expand="expandRegionHandle"
          bordered
        >
          <span
            slot="customCutInRate"
            slot-scope="text"
            :style="{ color: getColor(text) }"
          >
            {{ text }}
          </span>
          <span
            slot="customOnlineRate"
            slot-scope="text"
            :style="{ color: getColor(text) }"
          >
            {{ text }}
          </span>
          <span
            slot="customOfflineRate"
            slot-scope="text"
            :style="{ color: getColor(text) }"
          >
            {{ text }}
          </span>
          <span slot="totalAccessCount" slot-scope="text">
            {{ !!text ? text : '/' }}
          </span>
          <span slot="accessedRate" slot-scope="text">
            {{ !!text ? text : '/' }}
          </span>
        </a-table>
        <a-table
          :columns="columns"
          :data-source="orginationData"
          :rowKey="(record, index) => record.key"
          :pagination="false"
          @change="changeHandle"
          v-if="currentType === 'organization' && $g.isDepart"
          @expand="expandOrganizationHandle"
          bordered
        >
          <span
            slot="customCutInRate"
            slot-scope="text"
            :style="{ color: getColor(text) }"
          >
            {{ text }}
          </span>
          <span
            slot="customOnlineRate"
            slot-scope="text"
            :style="{ color: getColor(text) }"
          >
            {{ text }}
          </span>
          <span
            slot="customOfflineRate"
            slot-scope="text"
            :style="{ color: getColor(text) }"
          >
            {{ text }}
          </span>
          <span slot="totalAccessCount" slot-scope="text">
            {{ !!text ? text : '/' }}
          </span>
          <span slot="accessedRate" slot-scope="text">
            {{ !!text ? text : '/' }}
          </span>
        </a-table>
        <a-table
          :columns="columns"
          :data-source="data"
          :rowKey="(record, index) => record.key"
          :pagination="false"
          @change="changeHandle"
          v-if="load && !$g.isDepart && currentType !== 'organization'"
          bordered
        >
          <span
            slot="customCutInRate"
            slot-scope="text"
            :style="{ color: getColor(text) }"
          >
            {{ text }}
          </span>
          <span
            slot="customOnlineRate"
            slot-scope="text"
            :style="{ color: getColor(text) }"
          >
            {{ text }}
          </span>
          <span
            slot="customOfflineRate"
            slot-scope="text"
            :style="{ color: getColor(text) }"
          >
            {{ text }}
          </span>
          <span slot="totalAccessCount" slot-scope="text">
            {{ !!text ? text : '/' }}
          </span>
          <span slot="accessedRate" slot-scope="text">
            {{ !!text ? text : '/' }}
          </span>
        </a-table>
        <a-table
          :columns="columns"
          :data-source="dataOrganization"
          :rowKey="(record, index) => record.key"
          :pagination="false"
          @change="changeHandle"
          v-if="!$g.isDepart && currentType === 'organization'"
          bordered
        >
          <span
            slot="customCutInRate"
            slot-scope="text"
            :style="{ color: getColor(text) }"
          >
            {{ text }}
          </span>
          <span
            slot="customOnlineRate"
            slot-scope="text"
            :style="{ color: getColor(text) }"
          >
            {{ text }}
          </span>
          <span
            slot="customOfflineRate"
            slot-scope="text"
            :style="{ color: getColor(text) }"
          >
            {{ text }}
          </span>
          <span slot="totalAccessCount" slot-scope="text">
            {{ !!text ? text : '/' }}
          </span>
          <span slot="accessedRate" slot-scope="text">
            {{ !!text ? text : '/' }}
          </span>
        </a-table>
      </div>
    </vue-scroll>
    <!-- {{ selectList }} -->
  </div>
</template>

<script>
/*  */
import Back from '@/components/Back';
import { mapState, mapActions, mapMutations } from 'vuex';
import {
  createGuid,
  treeOrganiztionCheck,
  treeOrganiztionCheckNet,
} from '@/tools';
let columnInfo = [
  {
    title: '排序',
    customRender: (text, record, index) => index + 1,
    width: 140,
  },
  {
    title: '路线名称',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: {
      customRender: 'name',
    },
  },
  // 路线不显示这个
  {
    title: '应接入数',
    dataIndex: 'totalAccessCount',
    key: 'totalAccessCount',
    scopedSlots: {
      customRender: 'totalAccessCount',
    },
    type: 'filterItem',
  },

  {
    title: '实际接入数',
    dataIndex: 'accessedCount',
    key: 'accessedCount',
    scopedSlots: {
      customRender: 'accessedCount',
    },
    type: 'filterItem',
  },
  // 路线不显示这个
  {
    title: '接入率',
    dataIndex: 'accessedRate',
    key: 'accessedRate',
    // sorter: true,
    scopedSlots: {
      customRender: 'accessedRate',
    },
    type: 'filterItem',
  },
  {
    title: '在线数量',
    dataIndex: 'onlineCount',
    key: 'onlineCount',
  },
  {
    title: '在线率',
    dataIndex: 'onlineRate',
    key: 'onlineRate',
    // sorter: true,
    scopedSlots: {
      customRender: 'customOnlineRate',
    },
  },
  {
    title: '正常数',
    dataIndex: 'normalCount',
    key: 'normalCount',
  },
  {
    title: '正常率',
    dataIndex: 'normalRate',
    key: 'normalRate',
    // sorter: true,
    scopedSlots: {
      customRender: 'customOfflineRate',
    },
  },
  {
    title: '异常数',
    dataIndex: 'abnormalCount',
    key: 'abnormalCount',
    type: 'filterYun',
  },
  {
    title: '异常率',
    dataIndex: 'abnormalRate',
    key: 'abnormalRate',
    type: 'filterYun',
    // sorter: true,
  },
];
export default {
  name: 'NetCondition',

  data() {
    return {
      activeMenu: 0,
      subList: [
        {
          id: 0,
          name: '按路线',
          type: 'road',
          sort1: 3,
          sort2: 3,
        },
        {
          id: 1,
          name: '按区域',
          type: 'province',
          sort1: 1,
          sort2: 2,
        },
        {
          id: 2,
          name: '按单位',
          type: 'organization',
          sort1: 2,
          sort2: 1,
        },
      ],

      columns: columnInfo,
      currentName: null,
      regionId: [],
      organizationId: [],
      routeName: '',
      roadId: undefined,
      sortField: 0,
      sortType: 1,
      load: true,
      roadLists: [],
      regionCodeType: 1,
      regionCode: '',
      regionLists: [],
      isRegionChild: true,
      showExpand: false,
      orginationData: [],
      currentType: 'province',
      dataOrganization: [],
      organizationDataList: [],
      showSearch: false, //管辖单位搜索
      organizationRegion: '',
      copyOrginationData: [],
    };
  },
  components: {
    Back,
  },
  computed: {
    ...mapState({
      roadList: state => state.collect.roadList,
      organizationList: state => state.collect.organizationList,
      networkStatisticDetail: state => state.resource.networkStatisticDetail,
      selectList: state => state.collect.selectList,
    }),
    data() {
      return this.getData(this.networkStatisticDetail, '0');
    },
    organizationListFormat() {
      return this.organizationList.map(item => {
        // console.error(item);
        if (
          this.$g.regionCode !== 100000 &&
          item.value !== String(this.$g.regionCode)
        ) {
          item.disabled = true;
        }
        return item;
      });
    },
  },
  watch: {},
  created() {
    if (this.$g.isDepart) {
      this.subList = this.subList.sort((a, b) => a.sort1 - b.sort1);
      this.currentType = 'province';
      if (this.currentType !== 'province') {
        this.columns = this.filterColum(columnInfo);
      }
    } else {
      this.subList = this.subList.sort((a, b) => a.sort2 - b.sort2);
      this.currentType = 'organization';
      if (this.$g.isHideException) {
        this.columns = this.filterYunColum(columnInfo);
      } else if (this.currentType !== 'organization') {
        this.columns = this.filterColum(columnInfo);
      }
    }
    this.columns[1].title =
      this.currentType === 'road'
        ? '路线名称'
        : this.currentType === 'province'
        ? '区域名称'
        : '单位名称';
  },
  mounted() {
    this.getRoadList();
    // this.getOrganationList();
    this._getOrganationList();
    if (this.$g.isDepart) {
      this._getNetworkStatisticDepart();
    } else {
      if (this.currentType === 'organization') {
        this.getYunOrganizationCameral();
      } else {
        this._getNetworkStatisticsDetail();
      }
    }

    this.getSelectList({
      regionCode: window.g.regionCode,
      /*  regionCode: 500000, */
    });
  },
  methods: {
    ...mapActions([
      'getRoadList',
      'getOrganationList',
      'getProvince',
      'getNetworkStatisticsDetail',
      'exportNetworkStatistics',
      'getSelectList',
      'getByCompany',
    ]),
    ...mapMutations(['setNetworkStatisticDetail']),
    filterColum(columnInfo) {
      return columnInfo.filter(item => item.type !== 'filterItem');
    },
    filterYunColum() {
      let newColumn;
      newColumn = columnInfo.filter(item => item.type !== 'filterYun');
      if (this.currentType !== 'organization') {
        newColumn = newColumn.filter(item => item.type !== 'filterItem');
      }
      return newColumn;
    },
    getColor(text) {
      let ntext = parseInt(text);
      return `${
        ntext < 60
          ? '#FD4217'
          : ntext >= 60 && ntext < 80
          ? '#F09C23'
          : '#45CD8F'
      }`;
    },
    _getOrganationList() {
      let data = {
        regionCode: this.$g.regionCode === 100000 ? '' : this.$g.regionCode,
      };
      this.$api.aiCenterY.getOrganizationInfo(data).then(res => {
        if (res.code === 200) {
          this.organizationDataList = treeOrganiztionCheckNet(res.data);
        }
      });
    },
    getYunOrganizationCameral() {
      let data = {
        regionCode: this.$g.regionCode,
        organizationId:
          this.organizationId.length > 0
            ? this.organizationId[this.organizationId.length - 1]
            : '',
      };
      this.loading = true;
      this.setNetworkStatisticDetail([]);
      this.$api.home.cameraTreeByOrg(data).then(res => {
        if (res.code === 200) {
          // console.error(treeDataReCheck(res.data, 'yunOrganization'));
          this.loading = false;
          // let formatData = treeDataReCheck(res.data, 'yunOrganization');
          let formatData = treeOrganiztionCheck(res.data, true);
          // console.error(formatData);
          this.dataOrganization = formatData;
        }
      });
    },
    _getNetworkStatisticDepart() {
      if (this.currentType === 'road') {
        this.setNetworkStatisticDetail([]);
        this.getRoadStatis();
      } else if (this.currentType === 'province') {
        this.setNetworkStatisticDetail([]);
        this.getRegionStatis();
      } else if (this.currentType === 'organization') {
        this.setNetworkStatisticDetail([]);
        if (!this.showSearch) {
          let data = {
            groupType: 1,
          };
          this.getNetWorkByOrg(data);
        } else {
          let data = {
            groupType: 1,
          };
          let organizationId = this.organizationId;
          if (organizationId.length > 0) {
            if (
              organizationId[organizationId.length - 1] !==
              String(this.$g.regionCode)
            ) {
              data.organizationId =
                organizationId.length > 0
                  ? organizationId[organizationId.length - 1]
                  : '';
            }
          }
          this.getNetWorkByOrg(data);
        }
        /*  if (!this.showSearch) {
          this.$api.aiCenterY.getRegionInfo().then((res) => {
            if (res.code === 200) {
              this.orginationData = res.data.map((item) => {
                item.name = item.regionName;
                item.children = [];
                return item;
              });
            }
          });
        } else {
          let data = {
            regionCode: this.organizationRegion,
          };
          let organizationId = this.organizationId;
          if (organizationId.length > 0) {
            if (
              organizationId[organizationId.length - 1] !==
              String(this.$g.regionCode)
            ) {
              data.organizationId =
                organizationId.length > 0
                  ? organizationId[organizationId.length - 1]
                  : '';
            }
          }
          this.getByOrganization(data);
        } fix过去*/
      }
    },
    trackOrganization(children, data, copyOrginationData) {
      if (!children && children.length <= 0) {
        return;
      }
      let arr = [];
      arr = children.map((item, index) => {
        item.groupType = data.groupType + 1;
        item.key = `${createGuid()}-${index}`;
        if (data.groupType === 1) {
          item.name = item.regionName;
        } else if (data.groupType === 2) {
          item.name = item.organizationName;
        } else if (data.groupType === 3) {
          item.name = item.roadName;
        }
        if (data.groupType < 3) {
          item.children = [];
          /*  if (data.groupType === 2) {
            // debugger;
            item.children = copyOrginationData.map((child) => {
              if (child.regionCode === data.regionCode) {
                child.children = children;
              }
              return child;
            });
          } else if (data.groupType === 3) {
            item.children = copyOrginationData.map((child) => {
              child.children = child.children.map((cc) => {
                if (cc.organizationId === data.organizationId) {
                  cc.children = children;
                }
                return cc;
              });
              return child;
            });
          }*/
        }
        return item;
      });
      if (copyOrginationData && copyOrginationData.length > 0) {
        if (data.groupType === 2) {
          arr = copyOrginationData.map(child => {
            if (child.regionCode === data.regionCode) {
              child.children = children;
            }
            return child;
          });
        } else if (data.groupType === 3) {
          arr = copyOrginationData.map(child => {
            child.children = child.children.map(cc => {
              if (cc.organizationId === data.organizationId) {
                cc.children = children;
              }
              return cc;
            });
            return child;
          });
        }
      }

      return arr;
    },
    getNetWorkByOrg(data) {
      this.$api.aiCenterY.getNetWorkByOrg(data).then(res => {
        if (res.code === 200) {
          this.orginationData = this.trackOrganization(
            res.data,
            data,
            this.copyOrginationData
          );
          this.copyOrginationData = this.orginationData;
          /* this.orginationData = res.data.map((item) => {
            item.groupType = data.groupType + 1;
            if (data.groupType === 1) {
              item.name = item.regionName;
            } else if (data.groupType === 2) {
              item.name = item.organizationName;
            } else if (data.groupType === 3) {
              item.name = item.roadName;
            }
            if (data.groupType < 3) {
              // item.children = [];
              if(data.groupType === 2){
                item.children = this.copyOrginationData.map(child=>{
                  if(child.regionCode === data.regionCode){
                    child.children =
                  }
                })
              }
            }
            return item;
          }); */
          this.copyOrginationData = this.orginationData;
        }
      });
    },
    expandOrganizationHandle(expanded, record) {
      let data = {
        groupType: record.groupType,
        /* regionCode:record.groupType === 2? record.regionCode:, */
      };
      if (record.groupType === 2) {
        data.regionCode = record.regionCode;
      } else if (record.groupType === 3) {
        data.organizationId = record.organizationId;
      }
      this.getNetWorkByOrg(data);
      /* let organizationId = this.organizationId;
      if (organizationId.length > 0) {
        if (
          organizationId[organizationId.length - 1] !==
          String(this.$g.regionCode)
        ) {
          data.organizationId =
            organizationId.length > 0
              ? organizationId[organizationId.length - 1]
              : '';
        }
      } */
      // this.getByOrganization(data);
      /* this.$api.resource.getByCompany(data).then((res) => {
        if (res.code == 200) {
          // console.error(res.data);
          let provinceData = this.getData(res.data, '0');
          if (!this.showSearch) {
            this.orginationData = this.orginationData.map((item) => {
              if (item.regionCode === data.regionCode) {
                item.children = provinceData;
              }
              return item;
            });
          } else {
            this.orginationData = provinceData;
          }
        }
      }); */
    },
    getByOrganization(data) {
      this.$api.resource.getByCompany(data).then(res => {
        if (res.code == 200) {
          // console.error(res.data);
          let provinceData = this.getData(res.data, '0');
          if (!this.showSearch) {
            this.orginationData = this.orginationData.map(item => {
              if (item.regionCode === data.regionCode) {
                item.children = provinceData;
              }
              return item;
            });
          } else {
            this.orginationData = provinceData;
          }
        }
      });
    },
    getRegionStatis() {
      let data = {
        regionCodeType: this.regionCodeType,
        params: {
          regionCode: this.regionCode,
          regionCodeSearch:
            this.regionId.length > 0
              ? this.regionId[this.regionId.length - 1]
              : '',
        },
      };
      this.$api.resource.getCameraNetWorkByRegion(data).then(res => {
        if (res.code === 200) {
          this.regionCodeType = res.data.hierarchy;
          /*  let getSameChildFlag = this.getSameChild(
            this.regionLists,
            res.data.info
          ); */
          if (this.regionCodeType === 3) {
            this.isRegionChild = false;
          } else {
            this.isRegionChild = true;
          }
          let arr = this._getNormalRegionStatic(
            res.data.info,
            res.data.hierarchy
          );
          if (this.regionCodeType === 1 || data.params.regionCodeSearch) {
            this.regionLists = arr;
          } else {
            this.regionLists = this.regionLists.map(item => {
              if (
                item.children.length == 0 &&
                item.regionCode === this.regionCode
              ) {
                item.children = arr;
              } else if (item.children.length > 0) {
                item.children = item.children.map(child => {
                  if (child.regionCode === this.regionCode) {
                    child.children = arr;
                  }
                  return child;
                });
              }
              return item;
            });
          }
        }
      });
    },
    concatRegionList(regionList, arr) {
      return regionList.map(item => {
        if (item.regionCode === this.regionCode && this.regionCodeType === 2) {
          item.children = arr;
        }
        if (this.regionCodeType === 3 && item.children.length) {
          item.children = item.children.map(child => {
            if (child.regionCode === this.regionCode) {
              child.children = arr;
            }
            return child;
          });
        } else {
          item.children = arr;
        }
        return item;
      });
    },
    getSameChild(regionList, data) {
      let bool = false;
      if (regionList.length <= 0) {
        return false;
      }
      for (let i = 0; i < regionList.length; i++) {
        /*       debugger; */
        if (regionList[i].regionCode === data.regionCode) {
          bool = true;
          break;
        }
      }
      return bool;
    },
    expandRegionHandle(expanded, record) {
      if (!record.regionCode) {
        return;
      }
      /* this.regionCodeType =
        this.regionCodeType + 1 >= 3 ? 3 : this.regionCodeType + 1; */
      this.regionCodeType = record.level;
      this.regionCode = record.regionCode;
      //开始响应搜索不用传
      this.regionCodeSearch = '';
      this.regionId = [];
      this.showExpand = true;
      //结束
      this.getRegionStatis();
    },
    _getNormalRegionStatic(data, level) {
      let arr = [];
      arr = data.map(item => {
        item.key = `${item.regionCode}-${createGuid()}`;
        item.name = item.regionName;
        item.level = level + 1;
        /* let getSameChildFlag = this.getSameChild(this.regionLists, item);
        getSameChildFlag = this.showExpand ? false : getSameChildFlag; */
        /* if (this.isRegionChild && !getSameChildFlag) {
          item.children = [];
        } */

        if (this.isRegionChild) {
          item.children = [];
        }
        return item;
      });
      return arr;
    },
    getRoadStatis() {
      let data = {
        roadId: this.roadId,
      };
      this.$api.resource.getByRoad(data).then(res => {
        console.log('哈哈', res);
        if (res.code) {
          let arr = this._getNormalStatic(res.data);
          this.roadLists = arr;
        }
      });
    },
    _getNormalStatic(data) {
      let keys = Object.keys(data);
      let arr = [];
      for (let i = 0; i < keys.length; i++) {
        let obj = { name: keys[i], key: `${i}-${createGuid()}` };
        let child = data[keys[i]].map(item => {
          item.key = `${item.roadId}-${createGuid()}`;
          item.children = [];
          return item;
        });
        obj.children = child;
        arr.push(obj);
      }
      return arr;
    },
    clickRoadHandle(item) {
      let data = {
        roadId: item.roadId,
      };
      let roadListCopy = this.roadLists.slice();

      this.$api.resource.getByProvince(data).then(res => {
        if (res.code === 200) {
          let arr = res.data.map(item => {
            item.key = `provice-${createGuid()}`;
            return item;
          });
          this.roadLists = [];
          this.roadLists = roadListCopy.map(items => {
            items.children = items.children.map(child => {
              if (child.roadId === data.roadId) {
                child.children = arr;
              }
              return child;
            });
            return items;
          });
        }
      });
    },
    getData(dataList, preKey) {
      let arr = [];
      dataList.forEach((item, index) => {
        let joinKey = `${preKey}-${index}`;
        let obj = {};
        /* if (item.organizationName) {
          obj = { ...item, ...{ key: joinKey, name: item.organizationName } };
        } else {
          obj = { ...item, key: joinKey };
        } */
        obj = { ...item, key: joinKey };
        if (item.childList && item.childList.length > 0) {
          obj.children = this.getData(item.childList, joinKey);
        }
        arr.push(obj);
      });
      return arr;
    },

    displayRender({ labels }) {
      return labels[labels.length - 1];
    },
    selectRoad(val) {
      this.roadId = val;
    },
    selectOrganization(value, data) {
      // console.error('hha', value, data);
      this.organizationRegion = data && data[data.length - 1].regionCode;
      this.organizationId = value;
      this.showSearch = true;
    },
    changeProvince(val) {
      this.regionId = val;
      this.regionCodeType = val.length > 0 ? val.length : 1;
      //响应搜索为空开始
      this.regionCode = '';
      this.showExpand = false;
      //结束
    },
    exportNetworkStatisticsHandle() {
      if (!this.hasPermission(109211101011)) {
        this.permissionWarning();
        return;
      }

      let obj = {
        /*  currPage: this.paginationOpt.current,
        pageSize: this.paginationOpt.defaultPageSize, */
        groupType: this.activeMenu,
        sortField: this.sortField,
        sortType: this.sortType,
        roadId: this.roadId,
        regionCode:
          this.regionId.length > 0
            ? this.regionId[this.regionId.length - 1]
            : '',
        organizationId:
          this.organizationId.length > 0
            ? this.organizationId[this.organizationId.length - 1]
            : '',
      };
      this.exportNetworkStatistics(obj);
    },

    _getNetworkStatisticsDetail() {
      let obj = {
        /*  currPage: this.paginationOpt.current,
        pageSize: this.paginationOpt.defaultPageSize, */
        // groupType: this.activeMenu,
        groupType:
          this.currentType === 'road'
            ? 0
            : this.currentType === 'province'
            ? 1
            : 2,
        sortField: this.sortField,
        sortType: this.sortType,
        roadId: this.roadId,
        regionCode:
          this.regionId.length > 0
            ? this.regionId[this.regionId.length - 1]
            : '',
        organizationId:
          this.organizationId.length > 0
            ? this.organizationId[this.organizationId.length - 1]
            : '',
      };
      this.setNetworkStatisticDetail([]);
      this.getNetworkStatisticsDetail(obj);
    },

    expandHandle(expanded, record) {
      if (!record.roadId) {
        return;
      }
      this.clickRoads(record);
    },
    clickRoads(item) {
      let data = {
        roadId: item.roadId,
      };
      /* let roadListCopy = this.roadLists.slice(); */

      this.$api.resource.getByProvince(data).then(res => {
        if (res.code === 200) {
          let arr = res.data.map(item => {
            item.key = `provice-${createGuid()}`;
            return item;
          });
          item.children = arr;
        }
      });
    },

    tabClick(index, item) {
      /* if (item.name === '按区域') {
        this.currentName = '按区域';
      } else {
        this.currentName = null;
      } */
      this.activeMenu = index;
      this.currentType = item.type;
      this.columns[1].title =
        item.type === 'road'
          ? '路线名称'
          : item.type === 'province'
          ? '区域名称'
          : '单位名称';
      if (this.$g.isDepart) {
        if (this.currentType !== 'province') {
          this.columns = this.filterColum(columnInfo);
        } else {
          this.columns = columnInfo;
        }
      } else {
        if (this.$g.isHideException) {
          this.columns = this.filterYunColum(columnInfo);
        } else if (this.currentType !== 'organization') {
          this.columns = this.filterColum(columnInfo);
        } else {
          this.columns = columnInfo;
        }
      }
      /*  this.columns1[1].title =
        item.type === 'road'
          ? '路线名称'
          : item.type === 'province'
          ? '区域名称'
          : '单位单位'; */
      //清空初始状态
      this.sortField = 0;
      this.sortType = 1;
      // this._getNetworkStatisticsDetail();
      clearTimeout(this.timer);
      this.load = false;
      this.timer = setTimeout(() => {
        this.load = true;
      }, 0);
      this.resetHandle();
    },
    searchHandle() {
      if (this.$g.isDepart) {
        this.showExpand = false;
        this._getNetworkStatisticDepart();
      } else {
        if (this.currentType === 'organization') {
          this.getYunOrganizationCameral();
        } else {
          this._getNetworkStatisticsDetail();
        }
      }
    },
    resetHandle() {
      this.regionId = [];
      this.organizationId = [];
      this.roadId = undefined;
      //清空组织单位筛选
      this.showSearch = false;
      if (this.$g.isDepart) {
        this.regionCodeType = 1;
        this._getNetworkStatisticDepart();
      } else {
        if (this.currentType === 'organization') {
          this.getYunOrganizationCameral();
        } else {
          this._getNetworkStatisticsDetail();
        }
      }
    },
    changeHandle(pagination, filters, sorter) {
      // console.error(pagination, filters, sorter);
      switch (sorter.field) {
        case 'accessedRate':
          this.sortField = 0;
          break;
        case 'onlineRate':
          this.sortField = 1;
          break;
        case 'abnormalRate':
          this.sortField = 4;
          break;
        case 'normalRate':
          this.sortField = 3;
          break;
        default:
          this.sortField = 0;
      }
      switch (sorter.order) {
        case 'descend':
          this.sortType = 1;
          break;
        case 'ascend':
          this.sortType = 0;
          break;
        default:
          this.sortType = '';
          this.sortField = '';
      }
      this._getNetworkStatisticsDetail();
    },
  },
  destroyed() {
    clearTimeout(this.timer);
  },
};
</script>
<style scoped></style>
